import { Image, Menu } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { default as React } from 'react';
import { useNavigate } from 'react-router-dom';
import '../common/fun.css';

export default function Head() {
    const navigator = useNavigate()

    const items = [
        {
            label: (
                <a href="https://funtabs.cn" target="_self" rel="noopener noreferrer">
                    趣标签页
                </a>
            ),
            key: 'funtabs',
        },
    ];

    return (
        <Header className='header-div' >
            <div
                style={{
                    float: 'left',
                    display: 'flex',
                    cursor: 'pointer',
                    width: '140px'
                }}
                onClick={() => {
                    navigator('/')
                }}
            >
                <Image
                    src='/logo.svg'
                    height={34}
                    preview={false}
                />
                <span style={{
                    fontFamily: 'AliPuHui',
                    fontSize: '20px',
                    marginLeft: '12px',
                    paddingTop: '2px'
                }}>FunTabs</span>
            </div>
            <Menu
                theme="light"
                mode="horizontal"
                className='header-menu'
                items={items}
            />
        </Header>
    )
}
