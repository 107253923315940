import { GithubOutlined, MailFilled, QqOutlined, SafetyCertificateFilled, WechatOutlined } from '@ant-design/icons'
import { QRCode } from 'antd'
import Footer from 'rc-footer'
import 'rc-footer/assets/index.css'
import React from 'react'
import '../common/fun.css'

export default function Foot() {
  return (
    <Footer
      backgroundColor='#F1F3FA'
      style={{
        lineHeight: 1.5,
        padding: '0px 24px',
      }}
      columns={[
        {
          style: { margin: '0px' },
          title: '更多产品',
          items: [{
            icon: (
              <img src="logo.png" alt='' />
            ),
            title: '趣标签页',
            url: 'https://funtabs.cn',
            description: '高度可定制的个性导航'
          },
          ]
        }, {
          style: { margin: '0px' },
          title: '关于我们',
          items: [
            {
              icon: <img src='https://www.cloudflare.com/favicon.ico' alt=''/>,
              title: 'Cloudflare Pages',
              url: 'https://cloudflare.com',
              description: '提供页面托管'
            }, {
              icon: <GithubOutlined />,
              title: 'Github',
              url: 'https://github.com/dengxiwang',
              description: '@dengxiwang'
            }, {
              icon: <SafetyCertificateFilled />,
              title: '备案号：豫ICP备2023009053号-1',
              url: 'https://beian.miit.gov.cn/#/Integrated/index'
            }, {
              icon: <img src='https://www.beian.gov.cn/img/ghs.png' alt=''/>,
              url: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41072402000413',
              title: '豫公网安备 41072402000413号',
            },
          ]
        }, {
          style: { margin: '0px' },
          title: '联系我们',
          items: [
            {
              icon: <MailFilled />,
              title: '联系邮箱：1049220728@qq.com',
              LinkComponent: 'div'
            }, {
              icon: <QqOutlined />,
              title: 'QQ群：727809499',
              url: 'https://qm.qq.com/cgi-bin/qm/qr?k=6N9Y0wlXF5txRjJcBqSYByj0fDsNwjIs&authKey=ziF+0yZBKLQB8GFFDJEHTXMaz35chgIPb88v98Vwdytvym5UlNMWOBOEwMAEHlMj&noverify=0',
            }, {
              icon: <WechatOutlined />,
              title: '微信公众号：funtabs趣标签页',
              LinkComponent: 'div'
            },
          ]
        }, {
          style: { margin: '0px' },
          title: '关注公众号',
          items: [
            {
              title: (
                <QRCode
                  errorLevel="H"
                  value="http://weixin.qq.com/r/fziEnP-E-qKBrbv-921t"
                  icon="logo.png"
                  style={{ display: 'contents' }}
                />
              ),
              LinkComponent: 'div'
            }
          ]
        }
      ]}
      theme='light'
      bottom={
        <div className='hardware-acceleration' style={{ backgroundColor: '' }}>
          <div style={{ opacity: '0.4' }}>Made with ❤️ by</div>
          <div>funtabs趣标签页｜不同标签页面，相同有趣好玩～</div>
        </div>
      } />
  )
}
