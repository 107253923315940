import Main from "../components/content";
import Foot from "../components/footer";
import Head from "../components/header";

const HomePage = () => {

    const homepageStyle = {
        display: 'flex',
        flexDirection: 'column',
    }

    return (
        <div style={homepageStyle}>
            <Head />
            <Main />
            <Foot />
        </div>
    )
}

export default HomePage;