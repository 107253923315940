import { RightCircleOutlined } from '@ant-design/icons'
import { Button, Image, Space } from 'antd'
import React from 'react'
import '../common/fun.css'

export default function Main() {

  function joinUs() {
    window.open('https://qm.qq.com/cgi-bin/qm/qr?k=6N9Y0wlXF5txRjJcBqSYByj0fDsNwjIs&authKey=ziF+0yZBKLQB8GFFDJEHTXMaz35chgIPb88v98Vwdytvym5UlNMWOBOEwMAEHlMj&noverify=0')
  }

  const airBubbles = [
    { top: '100px', left: '24px', width: '100px', height: '100px' },
    { top: '80px', right: '24px', width: '100px', height: '100px' },
    { top: '250px', right: '80px', width: '200px', height: '200px' }
  ]

  function FirstProduct() {
    return (
      <>
        <Image
          src='/images/newtab.svg'
          preview={false}
          style={{
            padding: '50px 32px 24px 32px',
            maxWidth: '1100px'
          }}
        />
        <Space className='homepage-title' direction='vertical' size={24}>
          <span style={{ color: 'rgb(40 78 56)', borderBottom: '6px solid #40ab7f', lineHeight: '56px' }}>
            趣标签页，使您的浏览器起始页获得称号 “ 高度可定制 ” ！
          </span>
          <span style={{ color: '#686868', fontSize: '16px' }}>
            * 该作品不仅仅局限于在线网址访问，同时支持chrome，edge插件方式替换newtab新标签页！
          </span>
        </Space>
      </>
    )
  }

  return (
    <>
      <div className='content'>
        {/* 背景图片 */}
        <img
          src='/images/background.svg'
          style={{
            width: '100%',
            top: 0,
            position: 'absolute',
            minWidth: '2500px',
            zIndex: '-1'
          }}
          alt=''
        />
        {airBubbles.map((item, index) => {
          return (
            <div className='air-bubble' style={{ ...item }} key={index} />
          )
        })}
        <Space className='homepage-slogan' direction='vertical' size={24}>
          <span>
            不同标签页面，相同有趣好玩～
          </span>
          <span>
            Different Tab , But Same Fun !
          </span>
        </Space>
        <Button onClick={joinUs}>
          <RightCircleOutlined />
          加入我们
        </Button>
        <FirstProduct />
      </div>
    </>
  )
}
